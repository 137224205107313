* {
  font-family: "Montserrat";
  font-size: 12px;
}

a {
  text-decoration: none !important;
}

body {
  background: #F4F5FA !important;

}

.maplabel {
  font-weight: bold;
  font-size: .75rem !important;
}

.fa-file-pdf.file-thumbnail {
  color: tomato;
}

.fa-file-word.file-thumbnail {
  color: #0d6efd;
}

.fa-file-excel.file-thumbnail {
  color: #198754;
}


/* footer {
  background-color: #647393;
  position: absolute;
  bottom: 0;
  width: 100%;
  height: 60px;
} */
.footer {
  position: fixed;
  bottom: 0;
  width: 100%;
  background-color: #fff;
  z-index: 100;
  color: #000;
}

footer a {
  text-decoration: none;
}

.header {
  background: none;
}

.header,
.header a {
  color: #161e2c
}

.sidebar-header {
  color: #fff;
}

.btn-floating {
  border-radius: 50% !important;
}

.field-label {
  font-size: smaller;
}

.field-data {
  border-bottom: 1px solid #ccc;
}

.related-list-table a {
  text-decoration: none;
}

.btn-primary,
.btn-info {
  background-color: #6C757D !important;
  color: #fff !important;
  border-color: #6C757D !important;
}

.login-form {
  background-color: #fff;
  width: 100%;
  box-shadow: 0 1px 20px 0 rgb(69 90 100 / 8%);
  margin-top: 5rem;
}

.login-form i {
  color: #f7981c;

}

.login-section {
  border-left: 1px solid #ccc;
}

.login-form img {
  width: 100%;
}

.login-form .vr {
  border-right: 1px solid #ccc;
}

.data-table a {
  text-decoration: none;
}

.data-table th {
  background-color: #91969E !important;
  color: #fff !important;
  border: 1px solid #91969E;
  font-weight: 400;
  text-transform: uppercase;
  white-space: nowrap;
}

.data-table {
  border: 1px solid #91969E;
  border-radius: 5px;
}

.data-table tr td:first-child {
  text-transform: capitalize;
}

.view-form label {
  font-size: smaller;
  display: block;
  text-transform: uppercase;
  margin-top: .5rem;
}

.view-form-header {
  background-color: #91969E;
  color: #fff;
  padding: .1rem;
  border-radius: 5px 5px 0 0;
}

.form-detail,
.form-edit {
  border: 1px solid #ccc;
  padding-bottom: 1rem;
  background-color: rgba(255, 255, 255, .75);
}

.view-form span {
  display: block;
  border-bottom: 1px solid #ccc;
}

.fl {
  padding-left: 0px !important;
}

.fr {
  padding-right: 0px !important;
}

.data-table {
  font-size: smaller;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

input[type=number].no-spinner::-webkit-inner-spin-button,
input[type=number]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.react-pdf__Page {
  margin-top: 10px;
}

.react-pdf__Page__textContent {
  border: 1px solid darkgrey;
  box-shadow: 5px 5px 5px 1px #ccc;
  border-radius: 5px;
}

.react-pdf__Page__annotations.annotationLayer {
  padding: 20px;
}

.react-pdf__Page__canvas {
  margin: 0 auto;
}

/* For all pages */
.all-page-container {
  height: 100%;
  max-height: 500px;
  overflow: auto;
}

.myTable {
  width: 800px;
}

.fc-event {
  cursor: pointer;
}

table.fc-col-header {
  background-color: #161e2c;
}

table.fc-col-header a {
  color: #fff !important;
}

th.fc-col-header-cell {
  padding: .5rem 0 .5rem 0 !important;
  /*border: 1px solid #161e2c !important;*/
}

.btn-outline-primary {
  border: 1px solid !important;
  color: #333 !important;


}

.btn-outline-primary:hover {
  background-color: #91969E !important;
  color: #fff !important;
}

.btn-custom {
  border: 1px solid #1c3df7 !important;
  color: #fff !important;
  background: #1c3df7 !important;
}

/*
 * Progressbar
 */

.bar {
  --d: 1rem;
  /* arrow depth */
  --gap: 0.3rem;
  /* arrow thickness, gap */

  display: flex;
  margin-right: var(--d);
}

.bar-step {
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  padding: 0.6rem var(--d);
  margin-right: calc(var(--d) * -1 + var(--gap));
  background: #d3d7dd;
  color: #23468c;
  clip-path: polygon(0% 0%,
      calc(100% - var(--d)) 0%,
      100% 50%,
      calc(100% - var(--d)) 100%,
      0% 100%,
      var(--d) 50%);
  font-size: .8rem;
}

.bar-step:first-child {
  clip-path: polygon(0% 0%,
      calc(100% - var(--d)) 0%,
      100% 50%,
      calc(100% - var(--d)) 100%,
      0% 100%);
}


.bar-step.active {
  background: #23468c;
  color: #fff;
}

.bar-step.completed {
  background: rgb(79, 167, 86);
  color: #fff;
}

.ibs-edit-form {
  background-color: #fff;
  margin-bottom: .5rem;
}

.card-header {
  /* background-color: #546168 !important; */
  background-color: rgb(125 156 233) !important;
  color: #fff !important;
}



.section-header {
  background-color: #91969E;
  color: #fff;
  padding: .5rem;
  margin-top: 1rem !important;
  /* text-transform: uppercase; */
}

.form-select[required] {
  border-left: 4px solid red;
}

.Select[required] {
  border-left: 4px solid red;
}


input.form-control[required] {
  border-left: 4px solid red;
}

input.form-control[required] {
  border-left: 4px solid red;
}

textarea.form-control[required] {
  border-left: 4px solid red;
}

.form-select[required] {
  border-left: 4px solid red;
}

.custom-select {
  border-left: 4px solid red;
  border-radius: 5px;
}


._3ybTi {
  display: inline;
}

.mobilecard {
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
  flex-wrap: wrap;
  padding-right: 1rem;
}

.mobilecard span,
.mobilecard a {
  width: 50%;
  padding-bottom: .7rem;
}

.ql-container {
  min-height: 10rem;
  height: 100%;
  flex: 1;
  display: flex;
  flex-direction: column;
}

.ql-snow.ql-toolbar {
  display: block;
  background: #eaecec;
  border-top-left-radius: 0.5em;
  border-top-right-radius: 0.5em;
}

.ql-editor {
  height: 100%;
  flex: 1;
  overflow-y: auto;
  width: 100%;
}

.quill span {
  border: none;
}

.chat-input-group {
  text-align: right;
}

.chat-input-group span {
  border: none;
  display: inline-block;
}

.chat {
  background-color: #fff;
  border: 1px solid #e6e6e6;
  min-height: 200px;

}

.sendbtn {
  background: none;
  border: 1px solid #007bff;
  padding: .3rem 1rem .3rem .5rem;
  border-radius: 5px;
  background-color: #007bff;
  color: #fff;

}

.chat .chat-input {
  outline: none;

  border: 1px solid #e6e6e6;
  padding: 1rem;
  height: 8rem;

}

.dropdown-menu.show {
  right: 0 !important;
  left: auto !important;
  padding: 0;
}

.dropdown-toggle::after {
  display: none !important;
}

.dropdown-toggle {
  background: none !important;
}

.dropdown-toggle .show .nav-link {
  background: none;
}

.alert {
  margin: 0 !important;
}

.dropdown-menu .dropdown-item:active,
.dropdown-menu .dropdown-item.active {
  background: none;
}

.notification-area {
  width: 400px;
  position: absolute;
  right: 3rem;
  left: auto;
  top: 3rem;
  z-index: 10;
}

.icon-wrapper {
  position: relative;
  float: left;
}

*.icon-blue {
  color: Chartreuse;
}



.cic {
  text-align: center;
  vertical-align: middle;
}

/* .notify-number {
  background:tomato;
  width:18px;
  height:18px;
  margin:0;
  border-radius:51%;
  position:absolute;
  top:-10px;
  right:-10px;
  padding:3px;
  font-size: .8rem;
  color: #fff;
  font-weight: bold;
  text-align: center;
} */


.notify-number {
  background: tomato;
  width: 18px;
  height: 18px;
  margin: 0;
  border-radius: 51%;
  position: absolute;
  top: -10px;
  right: 6px;
  padding: 1px;
  font-size: .8rem;
  color: #fff;
  font-weight: bold;
  text-align: center;
}

.fade.alert.alert-success.alert-dismissible.show {
  border-radius: 0;
  ;
}

.nav-tabs .nav-link:focus,
.nav-tabs .nav-link:hover {
  color: var(--bs-nav-tabs-link-active-color) !important;
  background-color: #91969E !important;
  color: white !important;
}

.filter-section .nav-link {
  color: white !important
}

.filter-section .nav-link.active {
  border-bottom: 1px solid white !important
}

/* changes 03 oct 2024 */
.nav-tabs .nav-link.active {
  background-color: white !important;
  color: black !important;
}

.nav-link[aria-selected=false] {
  color: black !important;
}

/* .nav-link:hover, .nav-link:focus{
  color: #fff !important;
} */
.nav-link {
  color: #fff;
}


/* Dashboard Css */
/* App.css */
.dashboard-grid {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 20px;
}

.report-table {
  border: 1px solid #ccc;
  padding: 10px;
  border-radius: 5px;
  max-height: 300px;
  /* Adjust the maximum height as needed */
  overflow-y: auto;
}

.report-table table {
  width: 100%;
  border-collapse: collapse;
}

.report-table th,
.report-table td {
  padding: 8px;
  border-bottom: 1px solid #ccc;
}

.report-table th {
  text-align: left;
  background-color: #f0f0f0;
}

/* 
  Added by Abdul Pathan 15 july 2024
*/
.chat-window {
  height: 350px;
  /* overflow-y: scroll; */
  overflow-x: hidden;
}

/* Customize the scrollbar */
.chat-window::-webkit-scrollbar {
  width: 4px;
  /* Width of the scrollbar */
}

.chat-window::-webkit-scrollbar-track {
  background: #f1f1f1;
  /* Color of the scrollbar track */
}

.chat-window::-webkit-scrollbar-thumb {
  background: #888;
  /* Color of the scrollbar handle */
  border-radius: 10px;
  /* Rounded corners for the scrollbar handle */
}

.chat-window::-webkit-scrollbar-thumb:hover {
  background: #555;
  /* Color of the scrollbar handle on hover */
}













/* STARTA */

/* Conversation */

.conversation {
  /* height: 15%; */
  position: relative;
  /* background: #efe7dd url("https://cloud.githubusercontent.com/assets/398893/15136779/4e765036-1639-11e6-9201-67e728e86f39.jpg") repeat; */
  z-index: 0;
}

.conversation ::-webkit-scrollbar {
  transition: all .5s;
  width: 5px;
  height: 1px;
  z-index: 10;
}

.conversation ::-webkit-scrollbar-track {
  background: transparent;
}

.conversation ::-webkit-scrollbar-thumb {
  background: #b3ada7;
}

.conversation .conversation-container {
  /* height: 40%; */
  /* box-shadow: inset 0 10px 10px -10px #000000; */
  overflow-x: hidden;
  position: relative;
  /* padding: 0 16px; */
  margin-bottom: 5px;
}


/* Messages */

.message {
  color: #000;
  clear: both;
  line-height: 18px;
  font-size: 15px;
  padding: 8px;
  position: relative;
  margin: 8px 0;
  max-width: 45%;
  min-width: 45%;
  word-wrap: break-word;
  z-index: 1;
}

.message:after {
  position: absolute;
  content: "";
  width: 0;
  height: 0;
  border-style: solid;
}

.metadata {
  display: inline-block;
  float: right;
  padding: 0 0 0 7px;
  position: relative;
  bottom: -4px;
}

.metadata .time {
  color: rgba(0, 0, 0, .45);
  font-size: 9px;
  display: inline-block;
}

.message-actions {
  display: flex;
  justify-content: space-between;
  /* margin-top: 9px; */
}

.message-action {
  font-size: 0.9rem;
  color: #0078FF;
  text-decoration: none;
  margin-right: 10px;
  cursor: pointer;
  font-weight: 700;
}

/* .message:first-child {
  margin: 16px 0 8px;
} */

.message.received {
  background: #fff;
  border-radius: 0px 5px 5px 5px;
  float: left;
}

.message.received .metadata {
  padding: 0 0 0 16px;
}

.message.received:after {
  border-width: 0px 10px 10px 0;
  border-color: transparent #fff transparent transparent;
  top: 0;
  left: -10px;
}

.message.sent {
  background: rgb(227, 255, 201);
  border-radius: 5px 0px 5px 5px;
  float: right;
}

.message.sent:after {
  border-width: 0px 0 10px 10px;
  border-color: transparent transparent transparent rgb(227, 255, 201);
  top: 0;
  right: -10px;
}



/* AAAAAAA */


/* sppinner start */
.sk-cube-grid {
  width: 5rem;
  height: 5rem;
  margin: 10% auto;
}

.sk-cube-grid .sk-cube {
  width: 33%;
  height: 33%;
  background-color: #7296e7;
  float: left;
  -webkit-animation: sk-cubeGridScaleDelay 1.3s infinite ease-in-out;
  animation: sk-cubeGridScaleDelay 1.3s infinite ease-in-out;
}

.sk-cube-grid .sk-cube1 {
  -webkit-animation-delay: 0.2s;
  animation-delay: 0.2s;
}

.sk-cube-grid .sk-cube2 {
  -webkit-animation-delay: 0.3s;
  animation-delay: 0.3s;
}

.sk-cube-grid .sk-cube3 {
  -webkit-animation-delay: 0.4s;
  animation-delay: 0.4s;
}

.sk-cube-grid .sk-cube4 {
  -webkit-animation-delay: 0.1s;
  animation-delay: 0.1s;
}

.sk-cube-grid .sk-cube5 {
  -webkit-animation-delay: 0.2s;
  animation-delay: 0.2s;
}

.sk-cube-grid .sk-cube6 {
  -webkit-animation-delay: 0.3s;
  animation-delay: 0.3s;
}

.sk-cube-grid .sk-cube7 {
  -webkit-animation-delay: 0s;
  animation-delay: 0s;
}

.sk-cube-grid .sk-cube8 {
  -webkit-animation-delay: 0.1s;
  animation-delay: 0.1s;
}

.sk-cube-grid .sk-cube9 {
  -webkit-animation-delay: 0.2s;
  animation-delay: 0.2s;
}

@keyframes sk-cubeGridScaleDelay {

  0%,
  70%,
  100% {
    -webkit-transform: scale3D(1, 1, 1);
    transform: scale3D(1, 1, 1);
  }

  35% {
    -webkit-transform: scale3D(0, 0, 1);
    transform: scale3D(0, 0, 1);
  }
}

/* sppiner end */

.input-focus-class input:focus {
  outline: none;
}

/* START */
.card-header-grey {
  /* background-color: #546168 !important; */
  background-color: rgb(145, 150, 158) !important;
  color: #fff !important;
}

/* END */


.card-header-blue {
  background-color: rgb(50, 89, 224) !important;
  color: #fff !important;
}

::-webkit-scrollbar {
  width: 8px;
}

::-webkit-scrollbar-track {
  background: #f1f1f1;
}

::-webkit-scrollbar-thumb {
  background-color: #e4e4e4;
  border-radius: 10px;
}

::-webkit-scrollbar-thumb:hover {
  background: #e4e4e4;
}


/* ----------------------- Abhishek Sharma ------------------------------ */
.invoiceTable th, .invoiceTable td{
  border: 1px solid black;
}

.loader-container {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  backdrop-filter: blur(5px);
  z-index: 9999;
}

/* HTML: <div class="loader"></div> */
.loader {
  width: 50px;
  aspect-ratio: 1;
  border-radius: 50%;
  background: 
    radial-gradient(farthest-side,#758cc2 94%,#0000) top/8px 8px no-repeat,
    conic-gradient(#0000 30%,#758cc2);
  -webkit-mask: radial-gradient(farthest-side,#0000 calc(100% - 8px),#000 0);
  animation: l13 1s infinite linear;
}
@keyframes l13{ 
  100%{transform: rotate(1turn)}
}